
/*-------------------------------------
Template Name: Zinco  
Template URI: https://webtend.net/
Description: Digital Marketing Agency HTML Template.
Author: webtend
Author URI: https://ryetheme.com/
Version: 1.0
---------------------------------------*/

/*--------------------------------------
CSS INDEX:-

1.Default css
2.preloader css
3.index_1.html css
4.index_2.html css
5.zinco_breadcrumb section css
6.404.html section css
7.About.html css
8.SEO_service.html css
9.PPC_service.html css
10.SEM_service.html css
11.portfolio_2_column.html css
12.team.html css
13.testimonial.html css
14.faq.html css
15.shop.html css
16.shop_2.html css
17.shop_details.html 
18.blog_grid.html css
19.blog_standard.html css 
21.blog_details.html css
22.contact.html css
23.comeingsoon.html css

---------------------------------------*/
/*
	1. Satrt Default css 
*/
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800');
@font-face{
	src: url(../fonts/Gilroy-ExtraBold.ttf);
	font-family: 'Gilroy-ExtraBold.ttf';
}
@font-face{
	font-family: 'Gilroy-Light.ttf';
	src: url(../fonts/Gilroy-Light.ttf);
}
@font-face{
	font-family: 'Gilroy-Bold.ttf';
	src: url(../fonts/Gilroy-Bold.ttf);
}
@font-face{
	font-family: 'Gilroy-Regular.ttf';
	src: url(../fonts/Gilroy-Regular.ttf);
}
*{
	margin: 0;
	padding: 0;
	outline: none;
}

body
{
    overflow-x: hidde !important;
}
a,
a:hover,
a:focus{
	color: #170e2a;
	text-decoration: none;
	outline: none;
}
h1,h2,h3,h4,h5,h6{
	font-family: 'Gilroy-Bold.ttf';
	text-transform: capitalize;
	margin: 0;
	color: #42495b;
}
h1{
	font-size: 80px;
	line-height: 90px;
}
h2{
	font-size: 55px;
	line-height: 55px;
}
h3{
	font-size: 28px;
}
h4{
	font-size: 24px;
}
h5{
	font-size: 20px;
}
h6{
	font-size: 14px;
}
p{
	margin: 0;
}
ul{
	margin: 0;
	padding: 0;
}
li{
	list-style: none;
}
input,
input:hover,
input:focus{
	outline: none;
}
textarea,
textarea:hover,
textarea:focus{
	outline: none;
}
button,
button:hover,
button:focus{
	cursor: pointer;
	outline: none;
}
section{
	overflow: hidden;
}
body{
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	color: #7d8c95;
	line-height: 26px;
	overflow-x: hidden;
}
.section_padding{
	padding: 120px 0;
}
.section_padding_2{
	padding: 60px 0;
}
.zinco_overlay{
	background: #092232;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: .8;
}
.gray_bg{
	background: #f7f6ff;
}
.dark_bg{
	background: #092232;
}
.seomun_content_box{
	position: relative;
	display: block;
}
.seomun_content_box h2 span{
	font-size: 50px;
	font-weight: 300;
}
span.zinco_span {
	display: block;
    color: #ef3b58;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
    font-size: 16px;
}
.zinco_btn{
	display: inline-block;
	padding: 30px 75px;
	background: #ff7200;
	font-size: 15px;
	text-transform: capitalize;
	border-radius: 50px;
	border: none;
	outline: none;
	color: #fff;
	font-weight: 600;
	transition: all .5s;
}
.zinco_btn:after{
	display: inline-block;
	content: '\f105';
	font-family: 'Font Awesome 5 Pro';
	font-size: 12px;
	margin-left: 10px;
}
.zinco_btn:hover,
.zinco_btn:focus{
	background: #3767ff;
	color: #fff;
}
.zinco_section_title{
	margin-bottom: 74px;
}
.zinco_section_title span {
    display: block;
    color: #ef3b58;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 7px;
}
.zinco_section_title h2{
	font-size: 60px;
	margin-bottom: 5px;
}
.zinco_group{
	position: relative;
}
.zinco_group i{
	position: absolute;
	top: 30px;
	right: 40px;
	color: #ef3b58;
}
.dark_section_title h2{
	color: #fff;
}
.light_bg{
	background: #f4f8ff;
}
.pink_bg{
	background: #fff7f0;
}
.zinco_content_box span.zinco_span{
	font-size: 20px;
	line-height: 30px;
	letter-spacing: 2px;
	text-transform: uppercase;
	color: #ff7200;
	font-family: 'Gilroy-Bold.ttf';
	margin-bottom: 15px;
}
.zinco_content_box h2{
	line-height: 55px;
	color: #42495b;
	margin-bottom: 50px;
}
.zinco_content_box h2 span{
	color: #ff7200;
	font-style: italic;
}
.zinco_content_box p{
	margin-bottom: 30px;
}
.zinco_content_box .para_2{
	margin-bottom: 50px;
	font-weight: 600;
	font-style: italic;
}
.zinco_title {
	margin-bottom: 80px;
}
.zinco_title span.zinco_span {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #ff7200;
    font-family: 'Gilroy-Bold.ttf';
    margin-bottom: 15px;
}
.zinco_title h2{
	font-size: 50px;
	margin-bottom: 25px;
}
.blue_btn{
	background: #3767ff!important;
}
.blue_btn:hover,
.blue_btn:focus{
	background: #ff7200!important;
	color: #fff!important;
}
span.content_span{
	font-size: 20px;
	color: #ff7200;
	margin-bottom: 30px;
	display: block;
	text-transform: uppercase;
	font-weight: 600;
}
@media (min-width: 1200px){
	.container {
	    max-width: 1170px;
	}
}
/*
	End Default css 
*/

/*
	2. Start Preloader css 
*/
.preloader_area {
    position: fixed;
    background: #fff;
    z-index: 11000;
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.lodar{
	position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.lodar ul{
    display: flex;
}
.lodar ul li{
	display: inline-block;
	font-size:60px;
	font-weight: bold;
	font-family: 'Poppins';
	text-transform: uppercase;
	letter-spacing: 10px;
	animation: animate 1.4s linear infinite;
}
@keyframes animate{
	0%{
		color: #ef3b58;
	}
	50%{
		color: #ef3b58;
	}
	100%{
		color: #ef3b58;
	}
}
.lodar ul li:nth-child(1){
	animation-delay: .7s;
}
.lodar ul li:nth-child(2){
	animation-delay: 1s;
}
.lodar ul li:nth-child(3){
	animation-delay: 1.5s;
}
.lodar ul li:nth-child(4){
	animation-delay: 2s;
}
.lodar ul li:nth-child(5){
	animation-delay: 2.5s;
}
/*
	End Preloader css 
*/


/*==================================================
		3. SEOMUN==Index_1.html
====================================================*/
/*
	Start zinco_header area css
*/
.zinco_header_1{
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 337;
}
.header_container_1{
	max-width: 1560px;
    margin: 0 auto;
}
.header_container_2 {
    max-width: 1780px;
    margin: 0 auto;
}
header.sticky {
    position: fixed;
    top: 0;
    background: #6f0ac8;
    width: 100%;
    z-index: 337;
}
.sticky .main_menu ul li a{
	color: #fff;
}
.sticky .menu_button_box .select_option form select{
	color: #ff7200;
}
.sticky .menu_button_box ul li a{
	color: #fff;
}
.sticky .menu_button_box ul li:before{
	color: #fff;
}
.sticky .menu_button .phone_btn{
	color: #fff;
}
/*
	seomun_menu
*/
.zinco_menu{
	position: relative;
	float: right;
}
nav.main_menu {
    text-align: center;
}
.main_menu ul li{
	position: relative;
	display: inline-block;
	margin-left: 40px;
}
.main_menu ul li a{
	display: block;
	color: #474747;
	font-size: 17px;
	font-family: 'Gilroy-Regular.ttf';
	text-transform: capitalize;
	font-weight: 600;
	padding: 35px 0;
}
.main_menu ul li a span{
	margin-left: 10px;
}
.main_menu ul li:hover > a{
	color: #fff;
}
.main_menu ul li ul.sub_menu{
	position: absolute;
	text-align: left;
	width: 250px;
	max-height: 0px;
	padding: 0px;
    top: 100%;
    left: 0px;
    background: #fff;
    opacity: 0;
    z-index: 9999;
    border-top: 4px solid #ff7200;
    visibility: hidden;
	transition: all .3s;
}
.main_menu ul li:hover > ul.sub_menu{
	visibility: visible;
	top: 100%;
	max-height: 800px;
	padding: 15px;
	opacity: 1;
	transition: all .5s;
}
.main_menu ul li ul.sub_menu li{
	display: block;
	margin: 0;
}
.main_menu ul li ul.sub_menu li:last-child{
	border-bottom: none;
}
.main_menu ul li ul.sub_menu li a {
    font-size: 14px;
    color: #092f5d;
    padding: 10px 20px;
    text-transform: uppercase;
}
.main_menu ul li ul.sub_menu li:hover > a{
	color: #ef3b58;
}
.main_menu ul li ul.sub_menu li ul.sub_menu{
	left: 100%;
	top: 0;
	opacity: 0;
	visibility:hidden;
}
.main_menu ul li ul.sub_menu li:hover ul.sub_menu{
	visibility: visible;
	opacity: 1;
}
.menu_button_box {
    text-align: right;
}
.menu_button_box .select_option {
	position: relative;
	display: inline-block;
	margin-right: 30px;
}
.menu_button_box .select_option select{
	padding: 20px 40px;
	border: 2px solid #f98818;
	color: #fff;
	font-size: 17px;
	font-family: 'Gilroy';
	font-weight: bold;
	background: transparent;
	border-radius: 50px;
}
.menu_button_box .select_option_1 select{
	color: #42495b;
	border: none;
	background: #fff;
	box-shadow: 0px 16px 30px 0px rgba(76, 158, 242, 0.64);
}
.menu_button_box .select_option select option{
	color: #222;
	font-weight: 400;
	font-family: 'Open sans';
	background: transparent;
}
.menu_button_box .trial_btn{
	display: inline-block;
}
.trial_btn a.trial_btn {
    display: inline-block;
    padding: 20px 40px;
    background: rgb(255, 255, 255);
    color: #ff7200;
    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 50px;
    box-shadow: 0px 16px 30px 0px rgba(255, 114, 0, 0.64);
    transition: all .5s;
}
.menu_button_box .trial_phone{
	display: inline-block;
}
.menu_button_box .trial_phone .trial_btn{
	color: #fff;
	font-size: 17px;
	font-family: 'Gilroy-Bold.ttf';
}
.menu_button_box .trial_phone .trial_btn i{
	margin-right: 10px;
}
/*
	header_menu
*/
/*
	End zinco_header area css
*/


/*
	Start zinco_header_banner section css
*/
.header_banner_1{
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
	padding-top: 310px;
	padding-bottom: 210px;
}
.banner_container{
	width: 1280px;
	margin: 0 auto;
}
.banner_text,
.banner_button,
.zinco_img_box{
	position: relative;
	z-index: 1;
}
.banner_text h1 {
	font-size: 95px;
	color: #fff;
	margin-bottom: 38px;
}
.banner_text h1 span{
	font-weight: lighter;
	font-family: 'Gilroy-Light.ttf';
	font-size: 80px;
}
.banner_text p{
	color: #fff;
	font-family: 'Open Sans';
	margin-bottom: 50px;
	font-size: 24px;
}
.banner_btn{
	border: 2px solid #fff;
	color: #fff;
	background: transparent;
}
.banner_btn:hover,
.banner_btn:focus{
	background: #fff !important;
	color: #ff7200 !important;
}
/*
	End zinco_header_banner section css
*/

/*
	Satrt about_us section css
*/
.home_about{
	position: relative;
}
.about_container {
    position: relative;
    max-width: 1620px;
    margin: 0 auto;
    margin-top: 130px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
}
.home_about:after {
    position: absolute;
    top: -180px;
    left: 0;
    content: '';
    width: 100%;
    height: 960px;
    background: url(../images/shape/shape_4.png) no-repeat;
    z-index: -1;
}
.about_main_content{
	width: 535px;
	float: left;
	margin-right: 80px
}
.about_img_box{
	width: 470px;
	float: left;
	margin-right: 30px;
}
.about_content_box h2{
	font-size: 55px;
	line-height: 55px;
	margin-bottom: 30px;
}
.about_content_box h2 i{
	color: #ff7200;
}
.about_content_box p{
	margin-bottom: 40px;
}
.about_content_box h6{
	margin-bottom: 50px;
	line-height: 32px;
}
.about_content_box img{
	margin-bottom: 50px;
}

/*
	End about_us section css
*/

/*
	Satrt zinco_service_tab section css
*/
.service_nav {
    text-align: center;
}
.service_nav .nav-tabs {
    border-bottom: 1px solid #dee2e6;
    text-align: center;
    display: inline-flex;
}
.service_nav .nav-tabs li{
	margin-left: 45px;
	margin-right: 45px;
}
.service_nav .nav-tabs li .service_box_title .service_title_text {
    margin-left: 15px;
    text-align: left;
}
.service_nav .nav-tabs li .service_tab.active .service_box_title{
	border-bottom: 2px solid #ff7200;
}
.service_nav .nav-tabs li .service_box_title{
	border-bottom: none;
}
.service_tab_content{
	margin-top: 70px;
}
.home_client{
	position: relative;
	z-index: -1;
	overflow: visible;
	padding: 400px 0 130px;
}
/*
	End zinco_service_tab section css
*/


/*
	Start zinco_fun_fact section css
*/
.fun_fact_bg {
    position: relative;
    background: url(../images/fun_bg.png)no-repeat;
    padding: 100px 0px 130px;
    background-size: 100%;
}
/*
	End zinco_fun_fact section css
*/


/*
	Start zinco_how_work section css
*/
.zinco_feature_2{
	margin-top: 125px;
}
.work_arrow{
	position: relative;
}
.work_arrow:after {
    position: absolute;
    content: '';
    top: 50px;
    right: -300px;
    background: url(../images/shape/arrow_1.png) no-repeat;
    width: 100%;
    height: 100%;
    
}
.work_arrow:before {
    position: absolute;
    content: '';
    top: 50px;
    left: -80px;
    background: url(../images/shape/arrow_1.png) no-repeat;
    width: 100%;
    height: 100%;
}
.work_box{
	text-align: center;
}
.work_icon{
	position: relative;
	margin-bottom: 45px;
}
/*
	End zinco_how_work section css
*/

/*
	Start zinco_feature_4 section css
*/
.zinco_feature_area_3{
	background: #0d1549;
	position: relative;
	padding: 100px 40px;
}
.zinco_feature_3{
	display: block;
	margin-bottom: -280px;
	z-index: 1;
}
.zinco_feature_area_3:before {
    position: absolute;
    content: '';
    top: -140px;
    left: -130px;
    width: 100%;
    height: 100%;
    background: url(../images/shape/shape_5.png) no-repeat;
    transform: rotate(5deg);
}
.zinco_feature_area_3:after {
    position: absolute;
    content: '';
    bottom: 40px;
    right: 40px;
    width: 15%;
    height: 20%;
    background: url(../images/shape/shape_6.png) no-repeat;
}
.feature_content_box h2{
	color: #fff;
}
.feature_content_box p{
	color: #fff;
}
.zinco_feature_area_3 .feature_btn:hover,
.zinco_feature_area_3 .feature_btn:focus{
	background: #fff;
	color: #ff7200;
}
/*
	End zinco_feature_4 section css
*/

/*
	Start zinco_testimonial section
*/
.zinco_testimonial_1{
	position: relative;
}
.zinco_content_box h2 i{
	color: #ff7200;
}
.testimonial_slide_1 .slick-prev {
    top: 115%;
    left: -105%;
    z-index: 1;
}
.testimonial_slide_1 .slick-next {
    top: 115%;
    right: 0;
    left: -90%;
}
.testimonial_slide_1 .slick-prev:before{
	content: '\f104';
}
.testimonial_slide_1 .slick-next:before{
	content: '\f105';
}
.testimonial_slide_1 .slick-next:before,
.testimonial_slide_1 .slick-prev:before{
	display: block;
	font-family: 'Font Awesome 5 Pro';
    width: 60px;
  	height: 60px;
  	line-height: 60px;
  	text-align: center;
  	font-size: 18px;
  	color: #fff;
  	border-radius: 50%;
  	background: #ff7200;
  	z-index: 1;
  	transition: all .5s;
}
.testimonial_slide_1 .slick-next:hover:before,
.testimonial_slide_1 .slick-prev:hover:before,
.testimonial_slide_1 .slick-next:focus:before,
.testimonial_slide_1 .slick-prev:focus:before{
	background: #2d3779;
	color: #fff;
}
/*
	End zinco_testimonial section
*/

/*
	Start zinco footer css
*/
.zinco_footer_1{
	background: #f4f8ff;
	padding-top: 220px;
}
.zinco_footer_1 .zinco_copyright{
	border-top: none;
}
.widget_box_1 .footer_title h4{
	color: #42495b;
}
.widget_information{
	margin-bottom: -135px;
	display:block;
}
.widget_information_box{
	padding: 50px 40px;
	border-radius: 10px;
}
.widget_information_box h2{
	font-size: 36px;
	color: #fff;
}
.widget_information_box  p{
	color: #fff;
}
.info_box{
	display: flex;
	align-items: center;
	margin-bottom: 45px;
}
.info_icon{
	float: left;
}
.info_icon i{
	color: #fff;
	font-size: 24px;
}
.info_text p{
    font-size: 16px;
    color: #5f5f5f !important;
}
.info_text{
	margin-left: 15px;
}
.information_box_1{
	background: #13287e;
}
.information_box_2{
	background: #3767ff;
}
.information_box_3{
	background: #ff7200;
}
.widget_newsletter{
	background: #ff7200;
	padding: 40px;
	margin-bottom: 80px;
}
.zinco_footer_1 .footer_social ul li a{
	background: #fff;
	color: #8e8e8e;
}
.zinco_footer_1 .footer_social ul li a:hover,
.zinco_footer_1 .footer_social ul li a:focus{
	background: #ff7200;
	color: #fff;
}
.zinco_footer_2{
	background: #101c30;
}
.widget_area_2{
	padding: 100px 0;
}
.newsletter_content h3{
	color: #fff;
	font-family: 'Gilroy-bold.ttf';
}
.newsletter_form .form_control{
	float: left;
	width: 505px;
	height: 67px;
	background: #df6400;
	border-radius: 0;
	border: none;
	font-size: 16px;
	color: #ffcfa9;
	margin-right: 15px;
	margin-bottom: 0;
}
.newsletter_form input.form_control::placeholder{
	color: #ffcfa9;
}
.newsletter_form .newsletter_btn{
	width: 70%;
	// height: 67px; 
	// line-height: 10px;
	background: #fff;
	color: #ff7200;
	font-size: 25px;
	font-weight: 600;
	border-radius: 30px;
	text-align: center;
}
.newsletter_form .form a{
    display: flex;
    justify-content: center;
}
.footer_social{
	margin-top: 15px;
}
.footer_social ul li{
	display: inline-block;
	margin-right: 10px;
}
.footer_social ul li a{
	display: block;
	width: 35px;
	height: 35px;
	line-height: 35px;
	text-align: center;
	border-radius: 50%;
  	background: rgb(236, 106, 0);
  	color: #fff;
  	font-size: 14px;
  	transition: all .5s;
}
.footer_social ul li a:hover,
.footer_social ul li a:focus{
	background: rgb(255,255,255);
	color: rgb(236, 106, 0);
}
.footer_title{
	margin-bottom: 40px;
}
.footer_title h4{
	font-family: 'Gilroy-bold.ttf';
	color: #fff;
}
.widget_list li{
	line-height: 32px;
}
.widget_list li a{
	font-size: 14px;
	color: #6a7587;
}
.post_single{
	display: flex;
	margin-bottom: 30px;
}
.post_single .post_icon{
	float: left;
}
.post_single .post_icon i{
	color: #ff7200;
}
.post_single .post_info{
	margin-left: 15px;
}
.post_single .post_info p span{
	color: #fff;
	font-weight: 600;
}
.zinco_copyright{
	border-top: 2px solid #1b2e4c;
}
.copyright_text{
	padding: 30px 0;
}
.copyright_text p span{
	color: #ff7200;
}
/*
	End zinco footer css
*/


/*==================================================
		End Zinco==Index_1.html
====================================================*/


/*==================================================
	4.	Satrt Zinco==Index_2.html
====================================================*/
/*
	Satrt header_banner section css
*/
.header_banner_2{
	position: relative;
    padding-top: 200px;
    padding-bottom: 210px;
    z-index: 1;
}
.header_banner_2:after{
	position: absolute;
	content: '';
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: url(../images/shape/shape_1.png) no-repeat;
}
.header_banner_2:before{
	position: absolute;
	content: '';
	right: 0;
	top: 0;
	width: 40%;
	height: 100%;
	background: url(../images/shape/shape_2.png) no-repeat;
}
/*
	End header_banner section css
*/

/*
	Satrt zinco_features section css
*/
.feature_box_4,
.feature_box_4:hover{
	background: #fff!important;
}
.feature_box_4 .feature_icon{
	margin-bottom: 40px;
}
.feature_box_4 .feature_info h3:before {
    position: absolute;
    content: '';
    display: block;
    width: 100px;
    height: 3px;
    background: #ffe3cc;
    top: 0;
    left: 80px;
	font-size: 24px;
}
@media screen and (max-width:700px) {
	.feature_box_4 .feature_info h3:before {
		position: absolute;
		content: '';
		display: block;
		width: 100px;
		height: 3px;
		background: #ffe3cc;
		top: 0;
		left: 115px;
		font-size: 24px;
	}
}
.feature_box_4 .feature_info {
	text-align: center;
	position: relative;
}
.feature_box_4 .feature_info h3{
	padding-top: 35px;
	line-height: 35px;
}
.service_title_text h3{
	font-size: 25px;
}
.zinco_promo_text{
	margin-top: 130px;
}
.promo_content_box span.content_icon{
	margin-bottom: 30px;
	font-size: 30px;
	color: #ff7200;
	display: block;
}
.promo_content_box p i{
	color: #ff7200;
}
/*
	End zinco_features section css
*/

/*
	Start zinco_team_2 section css
*/
.zinco_team_2{
	position: relative;
}
.zinco_team_2:after{
	position: absolute;
	top: 0;
	left: 0;
	content: '';
	background: url(../images/shape/shape_3.png) no-repeat;
	width: 100%;
	height: 100%;
	z-index: -1;
}
.zinco_team_2 .team_box{
	margin-bottom: 0;
}
.no_padding{
	padding-right: 0px;
    padding-left: 0px;
}
.team_content_box h2{
	font-size: 50px;
	line-height: 55px;
	margin-bottom: 30px;
}
.team_content_box p{
	margin-bottom: 60px;
}
/*
	End zinco_team_2 section css
*/

/*
	Start blog section css
*/
.blog_box_2 .zinco_img_box img{
	width: 100%;
}
.blog_box_area{
	background: #fff;
	padding: 45px 35px;
}
.blog_box_area .blog_box {
	display: flex;
	margin-bottom: 40px;
}
.blog_box_area .blog_box .zinco_img_box{
	float: left;
	width: 90%;
}
.blog_box_area .blog_box .zinco_info{
	margin-left: 30px;
}
.blog_box_area .blog_box .zinco_info .post_meta {
    margin-bottom: 10px;
    margin-top: 0;
}
.blog_box_area .blog_box .zinco_info .post_content h3 {
    font-size: 18px;
    margin-bottom: 15px;
}
.blog_box_area .blog_box .zinco_info .post_content p {
    margin-bottom: 0px;
}
/*
	End blog section css
*/

/*==================================================
		END Zinco==Index_2.html
====================================================*/


/*
	5. Start zinco_breadcrumb section css
*/
.zinco_breadcrumb{
	position: relative;
	background: #ff7200;
	padding: 275px 0 180px;
}
.zinco_breadcrumb:after{
	position: absolute;
	content: '';
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: url(../images/bred_1.png) no-repeat;
}
.zinco_breadcrumb:before{
	position: absolute;
	content: '';
	right: 0;
	top: 0;
	width: 40%;
	height: 100%;
	background: url(../images/bred_2.png) no-repeat;
}
.breadcrumb_title h1{
	font-size: 65px;
	line-height: 100px;
	color: #fff;
}
.breadcrumb_title ul.breadcrumb_link li{
	display: inline-block;
}
.breadcrumb_title ul.breadcrumb_link li:first-child:before{
	 display: none;
}
.breadcrumb_title ul.breadcrumb_link li:before{
	font-size: 24px;
	content: '/';
	color: #fff;
	display: inline-block;
	margin-left: 5px;
	margin-right: 5px;
}
.breadcrumb_title ul.breadcrumb_link li a{
	color: #fff;
	font-size: 24px;
}
/*
	End zinco_breadcrumb section css
*/

/*
	6. Start 404.html css
*/
.error_content_box{
	margin-top: 130px;
}
.error_content_box h1{
	margin-bottom: 25px;
}
/*
	End 404.html css
*/


/*
	7. Start about_us.html css
*/
.counter{
	font-size: 55px;
    line-height: 55px;
	font-family: 'Gilroy-Bold.ttf';
    text-transform: capitalize;
    margin: 0;
    color: #42495b;
}
.about_counter{
	margin-top: 135px;
	
}
.about_counter_box{
	display: flex;
}
.about_counter_box .counter_icon{
	position: relative;
	float: left;
}
.about_counter_box .counter_icon i{
	font-size: 50px;
	color: #ff7200;
	margin-top: 20px;
	margin-left: 20px;
}
.about_counter_box .counter_icon:after {
    position: absolute;
    content: '';
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #fff1e5;
    top: 0;
    left: 0;
    z-index: -1;
}
.about_counter_box .counter_info{
	margin-left: 30px;
}
.about_counter_box .counter_info p{
	line-height: 55px;
	font-size: 25px;
	
}
.zinco_feature_box{
	padding: 60px 0;
	background: #ffe9d8;
	text-align: center;
	border-radius: 12px;
	transition: all .5s;
}
.zinco_feature_box:hover{
	background: #ff7200;
}
.zinco_feature_box:hover .feature_icon i{
	color: #fff;
}.zinco_feature_box:hover .feture_info h4{
	color: #fff;
}
.feature_icon i{
	font-size: 60px;
	color: #ff7200;
}
.feture_info{
	position: relative;
}
.feture_info img{
	margin-top: 15px;
}
.feture_info h4 {
    color: #42495b;
    margin-top: 15px;
}
.timeline_box {
    background: #fff7f0;
    padding: 35px 30px;
}
.timeline_box h5{
	margin-bottom: 30px;
}
.timeline_box p{
	line-height: 32px;
}
.timeline_box:after {
    position: absolute;
    content: '';
    border-top: 30px solid #fff7f0;
    border-left: 30px solid transparent;
    top: -30px;
    left: 15px;
    transform: rotate(180deg);
}
.timeline_main_content{
	margin-bottom: 80px;
}
.timeline_line{
	margin-bottom: 40px;
}
.team_box:hover .team_overlay{
	visibility: visible;
	opacity: .9;
}
.team_box:hover .team_content{
	opacity: 1;
}
.team_box .zinco_img_box{
	overflow: hidden;
	position: relative;
	}
.team_box .zinco_img_box img{
	width: 100%;
	height: 100%;
}
.team_overlay{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fb7912;
	opacity: 0;
	visibility: hidden;
	transition: all .5s;
}
 .team_content {
    position: absolute;
    width: 100%;
    bottom: 20%;
    left: 50%;
    text-align: center;
    opacity: 0;
    transform: translate(-50%,-20%);
    transition: all .5s;
}
.team_content h3{
	margin-bottom: 10px;
}
.team_content h3,
.team_content p{
	color: #fff;
}
.zinco_img_box .team_content ul li{
	display: inline-block;
	margin-left: 7px;
	margin-right: 7px;
}
.zinco_img_box .team_content ul li a{
	color: #fff;
}
.zinco_testimonial_3{
	background: #0d1549;
}
.bg_title h2{
	color: #fff;
}
.testimonial_box_3{
	background: #121b54;
	padding: 60px 40px;
	margin-left: 15px;
	margin-right: 15px;
}
.testimonial_box_3 .testimonial_text p{
    height: 120px;
	color: #fff;
	font-weight: 600;
}
.testimonial_client{
	margin-top: 30px;
	position: relative;
}
.testimonial_client:before{
	content: '\f10e';
	font-size: 70px;
	font-family: 'Font Awesome 5 Pro';
	color: #192259;
	right: 0;
	top: 20px;
}
.testimonial_client .like_h4{
	color: #fff;
	font-size: 22px;
	font-family: 'Gilroy-Bold.ttf';
	text-transform: capitalize;
	margin: 0;
}
// .testimonial_client p{
// 	color: #ff7200;
// 	letter-spacing: 2px;
// }
.testimonial_slide_3{
	margin-bottom: 70px;
}
.testimonial_slide_3 .slick-prev {
    top: 120%;
    left: 45%;
    z-index: 1;
}
.testimonial_slide_3 .slick-next {
    top: 120%;
    right: 45%;
}
.testimonial_slide_3 .slick-prev:before{
	content: '\f104';
}
.testimonial_slide_3 .slick-next:before{
	content: '\f105';
}
.testimonial_slide_3 .slick-next:before,
.testimonial_slide_3 .slick-prev:before{
	display: block;
	font-family: 'Font Awesome 5 Pro';
    width: 60px;
  	height: 60px;
  	line-height: 60px;
  	text-align: center;
  	font-size: 18px;
  	color: #2d3779;
  	border-radius: 50%;
  	background: transparent;
  	z-index: 1;
  	transition: all .5s;
}
.testimonial_slide_3 .slick-next:hover:before,
.testimonial_slide_3 .slick-prev:hover:before,
.testimonial_slide_3 .slick-next:focus:before,
.testimonial_slide_3 .slick-prev:focus:before{
	background: #2d3779;
	color: #fff;
}
.client_title h2{
	line-height: 55px;
	margin-bottom: 80px;
}
.clients_slide{
	margin-bottom: 80px;
}
.clients_single img{
    text-align: center;
    margin: 0 auto;
}
.clients_slide.slick-slider .slick-track,
.clients_slide.slick-slider .slick-list{
	display: flex;
	align-items: center;
}
/*
	End about_us.html css
*/


/*
	8.Start seo_service.html css
*/
.seo_comapny{
	margin-top: 130px;
}
.zinco_service_box{
	margin-bottom: 80px;
}
.service_box_title{
	display: flex;
	align-items: center;
	padding-bottom: 30px;
	border-bottom: 2px solid #e2e6ee;
}
.service_box_title .service_icon{
	float: left;
}
.service_box_title .service_icon i{
	font-size: 45px;
	color: #ff7200;
}
.service_box_title .service_title_text{
	margin-left: 15px;
}
.zinco_service_box .service_info{
	padding-top: 20px;
}
.zinco_service_box:hover .service_title_text h4{
	color: #ff7200;
}
.zinco_service_box .service_info h5{
	margin-bottom: 30px;
}
.zinco_service_box .service_info p{
	margin-bottom: 0;
}
.service_btn{
	padding: 18px 30px;
	background: transparent;
	border: 2px solid #e2e6ee;
	font-size: 15px;
	color: #42495b;
}
.service_btn:hover,
.service_btn:focus{
	color: #42495b;
}
.zinco_faq_acoridian .card{
	border: none;
    border-radius: 0;
    margin-bottom: 20px;
}
.zinco_faq_acoridian .card-header{
	position: relative;
    padding: 25px;
    border-radius: 0;
    border: 1px solid #e9e9e9;
    background: transparent;
}
.zinco_faq_acoridian .card .card-header a{
	font-size: 18px;
	font-family: 'Gilroy-Bold.ttf';
}
.zinco_faq_acoridian .card-body {
    border: 1px solid #e9e9e9;
    border-top: none;
}
.zinco_faq_acoridian .card .card-header a i.fal {
    display: none;
    position: absolute;
    right: -20px;
    top: 30px;
    color: #ef3b58;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    text-align: center;
    border: 1px solid #e9e9e9;
    border-radius: 50%;
    cursor: pointer;
    background: #fff;
}
.zinco_faq_acoridian .card .card-header a i.fal{
    display: none;
}
.zinco_faq_acoridian .card .card-header a[aria-expanded="true"] i.fal.fa-eye-slash{
    display: block;
}
.zinco_faq_acoridian .card .card-header a[aria-expanded="false"] i.fal.fa-eye {
    display: block;
}
.blog_box .zinco_info .post_meta {
	margin-top: 50px;
	margin-bottom: 30px;
}
.blog_box .zinco_info .post_meta ul li{
	display: inline-block;
	margin-right: 10px;
}
.blog_box .zinco_info .post_meta ul li a span{
	color: #ff7200;
	margin-right: 10px;
}
.blog_box .zinco_info .post_content h3{
	font-size: 22px;
	margin-bottom: 30px;
}
.blog_box .zinco_info .post_content p{
	margin-bottom: 35px;
}
.blog_box .zinco_info .post_content a.zinco_btn{
	padding: 25px 40px;
}
/*
	End seo_service.html css
*/


/*
	9. Start ppc_service.html css
*/
.ppc_feature_box{
	background: #fff;
	padding: 40px;
}
.ppc_feature_box .feature_icon{
	margin-bottom: 50px;
}
.ppc_feature_box .feature_info h4{
	margin-bottom: 30px;
}
.ppc_feature_box:hover{
	background: #fff;
}
.ppc_img_box{
	margin-bottom: 125px;
}
.ppc_feature .clients_slide{
	margin-bottom: 0;
}
.ppc_feature .client_title{
	margin-top: 160px;
}
.pricing_box.pricing_box_1{
	padding: 60px 0;
	text-align: center;
	background: #fff;
}
.pricing_title h3{
	color: #42495b;
	font-size: 35px;
}
.pricing_title h3 span{
	font-size: 20px;
}
.pricing_tag h2{
	font-family: 'Gilroy-Bold.ttf';
	font-size: 45px;
	color: #ff7200;
}
.pricing_tag h2 span{
	color: #42495b;
	font-size: 18px;
}
.pricing_body {
	padding: 70px 0;
}
.pricing_body ul li{
	line-height: 43px;
}
.pricing_button .pricing_btn{
	display: inline-block;
	padding: 20px 40px;
	background: #fff1e5;
	font-size: 15px;
	font-weight: 600;
	color: #fff;
	border-radius: 50px;
	text-transform: capitalize;
	transition: all .5s;
}
.pricing_button .pricing_btn:hover,
.pricing_button .pricing_btn:focus{
	background: #ff7200;
	color: #fff;
}
/*
	End ppc_service.html css
*/

/*
	10. Start sem_service.html css
*/
.zinco_cta_box h2{
	color: #fff;
}
.zinco_cta_box p{
	color: #fff;
}
.zinco_cta_box .why_btn{
	background: #fff;
	color: #ff7200;
}
.why_list_box{
	display: flex;
	margin-bottom: 30px;
}
.why_list_box .list_icon {
	float: left;
}
.why_list_box .list_icon i{
	display: block;
	width: 60px;
	height: 60px;
	line-height: 60px;
	text-align: center;
	border-radius: 50%;
	border: 1px solid #e4e5e8;
	color: #ff7200;
	font-size: 18px;
}
.why_list_box .list_text{
	margin-left: 25px;
}
.zinco_content_box .why_list_box .list_text p{
	margin-bottom: 0;
}
/*
	End sem_service.html css
*/

/*
	11. Start portfolio_2_column.html css
*/
.protfolio_box{
	margin-bottom: 30px;
}
.protfolio_box .zinco_info{
	padding: 30px 40px;
	background: #fff;
	text-align: center;
	box-shadow: 0px 16px 32px 0px rgba(236, 236, 236, 0.56);
}
.zinco_portfolio_2_column .zinco_button{
	margin-top: 30px;
}

.portolio_button{
	padding: 25px 35px;
	background: #fff;
	border: 1px solid #e9e9e9;
	border-radius: 100px;
	margin-bottom: 60px;
}
.portfolio_btn{
	padding: 20px 40px;
	border: 2px solid #e9e9e9;
	background: transparent;
	color: #42495b;
	font-family: 'Gilroy-Bold.ttf';
	text-transform: uppercase;
	font-size: 14px;
	border-radius: 50px;
	margin-left: 5px;
	margin-right: 5px;
}
.portfolio_btn:hover,
.portfolio_btn:focus,
.portfolio_btn.active_btn{
	background: #ff7200;
	color: #fff;
}
.protfolio_detail_img{
	margin-bottom: 70px;
}
.portfolio_discription_box{
	border: 10px solid #6e17f5;
	padding: 35px 45px;
}
.discription_list{
	margin-bottom: 45px;
}
.discription_list ul li{
	line-height: 32px;
}
.discription_list ul li span{
	font-weight: bold;
}
.social_share h5{
	margin-bottom: 20px;
}
.social_share ul li{
	display: inline-block;
	margin-left: 7px;
	margin-right: 7px;
}
.social_share ul li a{
	display: block;
	width: 35px;
	height: 35px;
	line-height: 35px;
	text-align: center;
	border-radius: 50%;
  	background: rgb(236, 106, 0);
  	color: #fff;
  	font-size: 14px;
  	transition: all .5s;
}
.social_share ul li a:hover,
.social_share ul li a:focus{
	background: rgb(255,255,255);
	color: rgb(236, 106, 0);
}
/*
	End portfolio_2_column.html css
*/

/*
	12.Start team.html css
*/
.team_box{
	margin-bottom: 30px;
}
.team_clients{
	margin-top: 130px;
}
/*
	End team.html css
*/

/*
	13.Start testimonial.html css
*/
.testimonial_box_2{
	padding: 60px 40px;
	background: #fff;
	margin-bottom: 30px;
}
.testimonial_box_2 .testimonial_client h4{
	color: #42495b;
}
.testimonial_box_2 .testimonial_client:before{
	color: #e8e9eb;
}
.testimonial_company{
	margin-top: 125px;
}
.testimonial_box_4{
	margin-left: 15px;
	margin-right: 15px;
}
.testimonial_box_4 .testimonial_text{
	position: relative;
	padding: 60px;
	border: 1px solid #e0e0f8;
}
.testimonial_box_4 .testimonial_text h4{
	color: #42495b;
}
.testimonial_box_4 .testimonial_client:before{
	color: #ff7200;
}
.testimonial_box_4 .testimonial_text:after {
    position: absolute;
    content: '';
    border-top: 30px solid #e0e0f8;
    border-left: 30px solid transparent;
    bottom: -16px;
    left: 190px;
    transform: rotate(135deg);
}
.testimonial_box_4 .testimonial_img{
	margin-top: 40px;
}
.testimonial_box_4 .testimonial_img img{
	margin: 0 auto;
}
/*
	End testimonial.html css
*/


/*
	14. Start faq.html css
*/
.zinco_faq_acoridian_2.zinco_faq_acoridian .card .card-header a i.fal{
	top: 20px;
}
.zinco_faq_contact{
	margin-top: 120px;
}
.faq_contact_form .form_control{
	border-radius: 0;
}
.faq_contact_form .form_btn{
	border-radius: 0;
	padding: 30px 140px;
}
/*
	End faq.html css
*/

/*
	15. Start shop.html css
*/
.shop_top_area{
	padding: 40px 60px;
	border: 2px solid #e1ecff;
	border-radius: 100px;
	margin-bottom: 80px;
}
.shop_top h4{
	font-size: 18px;
}
.shop_top_4{
	text-align: right;
}
.shop_top_4 h4 a{
	display: inline-block;
	padding: 10px;
	border: 1px solid #dddddd;
	font-size: 16px;
	margin-right: 10px;
}
.shop_box{
	margin-bottom: 40px;
}
.shop_product_info{
	text-align: center;
	padding: 40px 0;
}
ul.rateing li{
	display: inline-block;
	margin-left: 5px;
	margin-right: 5px;
}
ul.rateing li a{
	color: #ff7200;
	font-size: 14px;
}
.shop_product_info p{
	color: #ff7200;
	font-size: 16px;
	font-weight: 600;
}
/*
	End shop.html css
*/

/*
	16. Start shop_2.html css
*/
.ss_title{
	margin-bottom: 20px;
}
.ss_bottom{
	margin-bottom: 60px;
}
.shop_category ul li{
	line-height: 30px;
}
.shop_category ul li a{
	color: #666666;
	font-size: 16px;
}
.shop_category ul li a:after{
	display: block;
	content: '\f067';
	font-family: 'Font Awesome 5 Pro';
	float: right;
}
.filter_size ul li{
	display: inline-block;
	margin-right: 10px;
}
.filter_size ul li a {
    display: block;
    padding: 5px 10px;
    background: transparent;
    text-transform: uppercase;
    color: #5f5f5f;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #dddddd;
}
.filter_color ul li{
	display: inline-block;
}
.filter_color ul li a{
	display: block;
	width: 31px;
 	height: 30px;
 	margin-right: 10px;
}
.color_1{
	background: #775848;
}
.color_2{
	background: #252125;
}
.color_3{
	background: #252125;
}
.color_4{
	background: #b56c2f;
}
.color_5{
	background: #576579;
}
.shop_tag ul li{
	display: inline-block;
	margin-right: 10px;
	margin-bottom: 10px;
}
.shop_tag ul li a{
	display: block;
	padding: 8px 20px;
	background: transparent;
	color: #5f5f5f;
	font-size: 14px;
	font-weight: 600;
	border: 1px solid #dddddd;
}
/*
	Start shop_2.html css
*/

/*
	17. Start shop_details.html 
*/
.product_img_box {
    background: #f1f6fa;
    text-align: center;
    padding: 50px 0;
    overflow: hidden;
}
.product_img_box img{
	margin: 0 auto;
}
.product_thumb_slide{
	margin-top: 35px;
}
.product_thumb_slide .slick-list{
	padding: 0;
}
.product_thumb_img img{
	width: 170px;
	height: 180px;
}
.product_info_details{
	margin-bottom: 60px;
}
.product_info_details .product_info h2{
	font-size: 48px;
	font-weight: 600;
	margin-bottom: 15px;
}
.product_info_details .product_info h4{
	font-size: 30px;
	color: #ff5e14;
	margin-bottom: 35px;
}
.product_info_details .product_info h4 span{
	margin-left: 30px;
	color: #c3c3c3;
	font-weight: 400;
	text-decoration: line-through;
	text-decoration-color: #ff5e14;
}
.product_info_details .product_info ul.rateing{
	margin-bottom: 25px;
}
.product_info_details .product_info p{
	margin-bottom: 55px;
}
.select_item input {
    width: 43px!important;
    text-align: center;
    font-size: 14px;
    height: 41px;
    border: 1px solid #ddd;
}
.select_item .nice-number button{
	width: 43px;
	height: 43px;
	border: 1px solid #ddd;
	background: transparent;
}
.select_item .nice-number input[type=number]{
	color: #ff7200;
}
.product_share{
	margin-top: 30px;
}
.product_share ul li{
	display: inline-block;
	margin-right: 15px;
}
.product_share ul li a:hover{
	color: #ff7200;
}
.product_share ul li span{
	font-family: 'Gilroy-Bold.ttf';
	font-size: 14px;
	color: #42495b;
}
.product_reviews .zinco_product_nav ul.nav-tabs li a{
	font-family: 'Gilroy-bold.ttf';
	font-size: 24px;
	display: block;
	padding: 20px 55px 20px 0px;
}
.product_reviews .product_discription {
	padding-top: 40px;
}
.product_reviews .product_discription p{
	margin-bottom: 35px;
}
.product_reviews .zinco_product_nav ul.nav-tabs li a.product_nav_btn.active {
    color: #ff7200;
    border-bottom: 3px solid #ff5e14;
}
.cart_btn{
	display: inline-block;
	padding: 15px 40px;
	background: #ff7200;
	color: #fff;
	font-size: 15px;
	font-weight: 600;
	text-transform: capitalize;
	border: none;
	border-radius: 50px;
	margin-left: 20px;
}
.zinco_releted_product{
	margin-top: 70px;
}
/*
	End Shop_details.html
*/


/*
	18. Start blog_grid.html css
*/
.blog_box{
	margin-bottom: 80px;
}
.blog_btn{
	padding: 25px 40px;
	background: #ebf0ff;
	color: #42495b;
}
.blog_btn:hover{
	background: #3767ff;
}
.zinco_pagination ul li{
	display: inline-block;
	margin-left: 5px;
	margin-right: 5px;
}
.zinco_pagination ul li a{
	display: block;
	width: 50px;
  	height: 50px;
  	line-height: 50px;
  	text-align: center;
  	border-radius: 50%;
  	border: 2px solid #eaeffa;
  	background: transparent;
  	color: #5f5f5f;
  	transition: all .5s;
}
.zinco_pagination ul li a:hover,
.zinco_pagination ul li a:focus{
	background: #ff7200;
	color: #fff;
	border-color: #ff7200;
}
.sidebar_add .zinco_img_box{
	position: relative;
}
.sidebar_add .zinco_img_box img{
	width: 100%;
}
/*
	End blog_grid.html css
*/

/*
	19. Start blog_standard.html css
*/
.post_btn a.share_btn{
	display: inline-block;
	float: right;
	margin-top: 10px;
	width: 50px;
  	height: 50px;
  	line-height: 50px;
  	text-align: center;
  	border-radius: 50%;
  	background: rgb(255, 114, 0);
  	color: #fefefe;
  	font-size: 18px;
}
.zinco_sidebar{
	background: #fff9f5;
	padding: 50px 30px;
}
.search_widget .form_control{
	border-radius: 0;
	background: #fff;
	height: 60px;
}
.search_widget  form{
	position: relative;
}
.search_btn{
	position: absolute;
	top: 18px;
	right: 25px;
	border: none;
	background: transparent;
	font-size: 14px;
	color: #333333;
}
.sidebar_widget_box{
	background: #fff;
	border: 1px solid #e9e9e9;
	padding: 40px 30px;
	margin-bottom: 30px;
}
.zinco_img_box{
	position: relative;
}
.overlay_btn {
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    padding-left: 10px;
    padding-right: 10px;
}
.overlay_btn .banner_btn {
    display: inline-block;
    padding: 65px;
    background: #ff7200;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 2px;
    text-align: center;
    border: none;
    line-height: 30px;
}
.tags_list li{
	display: inline-block;
}
.sidebar_widget_title{
	margin-bottom: 40px;
	position: relative;
}
.sidebar_widget_title h3{
	padding-bottom: 15px;
	border-bottom: 2px solid #dfdcfa;
}
.sidebar_widget_title h3:after{
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
	width: 60px;
	height: 2px;
	background: #ff7200;
}
.tags_list .tag_btn{
	padding: 12px 15px;
	display: inline-block;
	background: #f0f4ff;
	font-size: 13px;
	text-transform: uppercase;
	margin-bottom: 10px;
}
.categories_list li{
	margin-bottom: 25px;
	padding-bottom: 15px;
	border-bottom: 1px solid #dfdcfa;
}
.categories_list li a{
	display: block;
	font-size: 14px;
	color: #5f5f5f;
}
.categories_list li:last-child{
	border: none;
}
.feeds_single{
	display: flex;
    text-align: left;
    overflow: hidden;
    margin-bottom: 40px;
}
.feeds_single .feeds_images{
	width: 28%;
	float: left;
	margin-right: 15px;
}
.feeds_single .feeds_images img{
	width: 100%;
}
.feeds_single .feeds_info{
	width: 65%;
    overflow: hidden;
}
.feeds_single .feeds_info h3{
	display: block;
	color: #42495b;
	font-size: 14px;
	margin-bottom: 5px;
}
/*
	End blog_standard.html css
*/

/*
	20. Start blog_details.html css
*/
.single_blog_content .zinco_info{
	position: relative;
}
.single_blog_content .zinco_info .zinco_img_box img{
	width: 100%;
}
.single_blog_content .zinco_info .post_meta {
    position: absolute;
    width: 454px;
    height: 63px;
    background: rgb(255, 255, 255);
    line-height: 60px;
    left: 30px;
    bottom: -20px;
    z-index: 1;
    text-align: center;
}
.single_blog_content .zinco_info .post_meta ul li{
	display: inline-block;
	margin-right: 20px;
}
.single_blog_content .zinco_info .post_meta ul li a span{
	margin-right: 10px;
	color: #ff7200;
}
.single_blog_content .single_content_box{
	padding: 60px 30px;
}
.single_blog_content .single_content_box p{
	line-height: 32px;
}
.single_blog_content .single_content_box .post_content h3{
	margin-bottom: 30px;
}
.zinco_blockquote{
	background: #ff7200;
	padding: 60px 40px;
	margin: 60px 0;
}
.zinco_blockquote .qoute_text h3{
	font-size: 30px;
	color: #fff;
	line-height: 37px;
	margin-bottom: 30px;
}
.zinco_blockquote .qoute_text h6{
	color: #fff;
	font-size: 16px;
}
.content_share_area{
	margin-bottom: 80px;
}
.tags_area h4{
	margin-bottom: 20px;
}
.tags_area ul.tags_list li{
	display: inline-block;
}
.tags_area ul.tags_list li a{
	font-size: 14px;
	color: #5f5f5f;
}
ul.social_link_2 li{
	display: inline-block;
	margin-left: 15px;
}
ul.social_link_2 li a{
	color: #5f5f5f;
	font-size: 14px;
}
.prev_next_area{
	margin-bottom: 80px;
}
.prev_next_text h4{
	font-size: 16px;
}
.prev_next_area .icon_box{
	text-align: center;
}
.prev_next_area .icon_box i{
	font-size: 31px;
	color: #ff7200;
}
.blog_admin{
	margin-bottom: 110px;
}
.about_admin_area{
	background: #101c30;
	display: flex;
	padding: 65px 60px;
	align-items: center;
}
.about_admin_area .admin_images{
	float: left;
}
.about_admin_area .admin_images img{
	border-radius: 50%;
}
.about_admin_area .admin_bio{
	margin-left: 15px;
}
.about_admin_area .admin_bio ul.social_link_2 li{
	margin-left: 0;
	margin-right: 15px;
	color: #a39cd6;
}
.about_admin_area .admin_bio h4{
	color: #fff;
	margin-bottom: 30px;
}
.about_admin_area .admin_bio p{
	margin-bottom: 30px;
}
.comment_area{
	margin-bottom: 80px;
}
.comment_title{
	position: relative;
	margin-bottom: 35px;
}
.comment_title h4{
	padding-bottom: 25px;
}
.comment_title h4:after{
	position: absolute;
	content: '';
	width: 30px;
	height: 2px;
	background: #ff7200;
	bottom: 0;
	left: 0;
}
.comment_list_area .single_comment{
	display: flex;
	align-items: center;
	margin-bottom: 60px;
}
.comment_list_area .single_comment:nth-child(even){
	padding-left: 55px;
}
.comment_list_area .single_comment .comment_images{
	float: left;
}
.comment_list_area .single_comment .comment_images img{
	border-radius: 50%;
}
.comment_list_area .single_comment .comment_text{
	margin-left: 30px;
}
.comment_list_area .single_comment .comment_text h5 span{
	margin-left: 10px;
	color: #5f5f5f;
}
.comment_list_area .single_comment .comment_text h5{
	margin-bottom: 30px;
}
.comment_list_area .single_comment .comment_text p{
	margin-bottom: 30px;
}
.comment_list_area .single_comment .comment_text a.reply_btn {
	font-family: 'Gilroy-Bold.ttf';
	font-size: 13px;
}
.comment_list_area .single_comment .comment_text a.reply_btn span{
	margin-left: 10px;
}
.post_form .form_control{
	border-radius: 0;
}
.post_form .form_control:focus{
	border-color: #ff7200;
}
/*
	End blog_details.html css
*/

/*
	22. Start contact.html css
*/
.contact_map_area iframe{
	width: 100%;
	height: 800px;
}
.form_title{
	margin-bottom: 60px;
}
.zinco_group{
	position: relative;
}
.zinco_group i{
	color: #ff7200;
}
.form_control{
	width: 100%;
	height: 70px;
	font-size: 15px;
	color: #5f5f5f;
	font-weight: 600;
	border: 2px solid rgb(234, 237, 244);
	text-transform: capitalize;
	border-radius: 8px;
	margin-bottom: 30px;
	padding-left: 30px;
}
textarea.form_control {
	height: 190px;
	padding-top: 30px;
}
.info_single_box {
	display: flex;
	margin-bottom: 60px;
}
.info_single_box .info_icon i{
	display: block;
	width: 80px;
  	height: 80px;
  	line-height: 80px;
  	text-align: center;
  	border-radius: 50%;
  	color: #ff7200;
  	font-size: 24px;
  	background: rgb(255, 255, 255);
  	box-shadow: 0px 20px 29.7px 0.3px rgba(180, 180, 180, 0.36);
}
.info_single_box .info_text{
	padding-left: 30px;
}
/*
	End contact.html css
*/

/*
	23. Start comeingsoon.html css
*/
.comesoon_area{
	position: relative;
	padding: 115px 0;
	background: rgba(239,248,254,1);
	background: -moz-linear-gradient(left, rgba(239,248,254,1) 0%, rgba(170,211,250,1) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(239,248,254,1)), color-stop(100%, rgba(170,211,250,1)));
	background: -webkit-linear-gradient(left, rgba(239,248,254,1) 0%, rgba(170,211,250,1) 100%);
	background: -o-linear-gradient(left, rgba(239,248,254,1) 0%, rgba(170,211,250,1) 100%);
	background: -ms-linear-gradient(left, rgba(239,248,254,1) 0%, rgba(170,211,250,1) 100%);
	background: linear-gradient(to right, rgba(239,248,254,1) 0%, rgba(170,211,250,1) 100%);
	z-index: 1;
}
.comesoon_area:after{
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: url(../images/come_2.png) no-repeat;
	z-index: -1;
}
.come_text_area h1{
	font-family: 'Gilroy-Bold.ttf';
	font-size: 65px;
	color: #ff7200;
	line-height: 90px;
}
.come_text_area h1 span{
	color: #252525;
}
.counter-column{
	display: inline-block;
	margin-right: 30px;
}
.offer_timer{
	margin-top: 66px;
}
.inner{
	font-size: 24px;
	color: #5f5f5f;
	text-transform: uppercase;
}
.inner span.count{
	display: block;
	font-size: 80px;
	font-family: 'Gilroy-Bold.ttf';
	line-height: 90px;
	color: #ff7200;
	margin-bottom: 4px;
}
/*
	Start comeingsoon html css
*/

.col-lg-3.fix-lg{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}


.ssafield
        {
          display: none;
        }
        
        .ssafield.show
        {
            display: flex !important;
            align-items: center;
            justify-content: center;
            position: fixed;
            width: 100%;
            height: 100vh;
            left: 0;
            top:0;
            background: rgba(255, 255, 255, 0.6);
            z-index: 99999999999999999999;
        }
        
        
        .ssafield.show >div
        {
            position: relative;
            
            
        }
        .ssafield.show >div > .cross
        {
            width: 30px;
            height: 30px;
            position: absolute;
            right: -60px;
            top:0;
            background: white;
            border-radius: 2px;
            cursor: pointer;
            
        }
         .ssafield.show >div > .cross >div
        {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;        
            justify-content: center;
            width: 100%;
            height: 100%;
        }
        
        .ssafield.show >div > .cross >div>div
        {
            width: 80%;
            height: 2px;
            background: black;
            position: absolute;
            
        }
        
        .ssafield.show >div > .cross >div>div.l1
        {
            transform: rotate(45deg)
        }
         .ssafield.show >div > .cross >div>div.l2
        {
            transform: rotate(-45deg)
        }