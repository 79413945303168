
/*! #######################################################################

	MeanMenu 2.0.7
	--------

	To be used with jquery.meanmenu.js by Chris Wharton (http://www.meanthemes.com/plugins/meanmenu/)

####################################################################### */

/* hide the link until viewport size is reached */
a.meanmenu-reveal {
	display: none;
}

/* when under viewport size, .mean-container is added to body */
.mean-container .mean-bar {
	float: left;
	width: 100%;
	position: relative;
	background: #0c1923;
	padding: 4px 0;
	min-height: 42px;
	z-index: 999999;
}
.mean-container a.meanmenu-reveal {
	width: 22px;
	height: 22px;
	padding: 13px 13px 11px 13px;
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
	color: #fff;
	text-decoration: none;
	font-size: 16px;
	text-indent: -9999em;
	line-height: 22px;
	font-size: 1px;
	display: block;
	font-family: 'Poppins', sans-serif;
	font-weight: 700;
}

.mean-container a.meanmenu-reveal span {
	display: block;
	background: #fff;
	height: 3px;
	margin-top: 3px;
}

.mean-container .mean-nav {
	float: left;
	width: 100%;
	background: #010e2a;
	margin-top: 15px;
}

.mean-container .mean-nav ul {
	padding: 0;
	margin: 0;
	width: 100%;
	list-style-type: none;
}

.mean-container .mean-nav ul li {
	position: relative;
	float: left;
	width: 100%;
}

.mean-container .mean-nav ul li a {
	display: block;
	float: left;
	width: 90%;
	padding: 1em 5%;
	margin: 0;
	text-align: left;
	color: #fff;
	border-top: 1px solid #fff;
	text-decoration: none;
	text-transform: capitalize;
}

.mean-container .mean-nav ul li li a {
	width: 80%;
	padding: 1em 10%;
	border-top: 1px solid #fff;
	text-shadow: none !important;
	visibility: visible;
}

.mean-container .mean-nav ul li.mean-last a {
	border-bottom: none;
	margin-bottom: 0;
}

.mean-container .mean-nav ul li li li a {
	width: 70%;
	padding: 1em 15%;
}

.mean-container .mean-nav ul li li li li a {
	width: 60%;
	padding: 1em 20%;
}

.mean-container .mean-nav ul li li li li li a {
	width: 50%;
	padding: 1em 25%;
}

.mean-container .mean-nav ul li a:hover {
	background: #ef3b58;
}
.mean-container .mean-nav ul li a.mean-expand {
	margin-top: 1px;
	width: 26px;
	height: 30px;
	padding: 12px !important;
	text-align: center;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 2;
	font-weight: 700;
	background: #010e2a;
	border: none !important;
	border-left: 1px solid rgba(255,255,255,0.4) !important;
	border-bottom: 1px solid rgba(255,255,255,0.2) !important;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
	background: #ef3b58;
}

.mean-container .mean-push {
	float: left;
	width: 100%;
	padding: 0;
	margin: 0;
	clear: both;
}

.mean-nav .wrapper {
	width: 100%;
	padding: 0;
	margin: 0;
}

/* Fix for box sizing on Foundation Framework etc. */
.mean-container .mean-bar, .mean-container .mean-bar * {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}


.mean-remove {
	display: none !important;
}


.mean-bar
{
   
}
.mean-bar a.fix
{
   
   
    display: flex;
   height:30px;
    padding-top: 10px;
    padding-left: 10px;
    
    
    align-content: center;
}
.mean-bar a.fix img
{
    height: 100%;
}

.mean-bar a.meanmenu-reveal
{margin-top: 7px;}

