/* Learge Layout: 1280px. */
@media only screen and (min-width: 1360px) and (max-width: 1420px) {
	.header_container_1 {
	    max-width: 1260px;
	    margin: 0 auto;
	}
	.header_container_2{
		max-width: 1260px;
	    margin: 0 auto;
	}
	.main_menu ul li {
		margin-left: 25px;
	}
	.main_menu ul li a {
	    font-size: 14px;
	}
	.menu_button a.phone_btn {
	    font-size: 10px;
	}
	.about_container{
		max-width: 1280px;
	}
	.about_main_content{
		width: 400px;
		margin-right: 20px
	}
	.about_img_box{
		width: 350px;
		margin-right: 10px
	}
	.about_main_content{
		width: 535px;
		float: left;
		margin-right: 20px
	}
	.about_content_box span{
		    margin-bottom: 10px!important;
	}
	.about_content_box h2{
		font-size: 40px;
		line-height: 45px;
	}
	.about_content_box img{
		margin-bottom: 10px;
	}
	.about_content_box p{
		margin-bottom: 20px;
	}
	.about_content_box h6{
		margin-bottom: 20px;
	}
	.testimonial_container{
		max-width: 1280px;
	}
}
@media only screen and (min-width: 1201px) and (max-width: 1360px) {
	.header_container {
	    max-width: 1200px;
	    margin-left: 15px;
	    margin-right: 15px;
	}
	.header_container_2 {
	    max-width: 1170px;
	}
	.header_menu.header_menu_1 {
	    max-width: 1100px;
	}
	.main_menu ul li a {
	    font-size: 10px;
	}
}
/* Medium Layout: 1280px. */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.header_container_1,
	.header_container_2{
	    max-width: 100%;
	    padding-left: 15px;
	    padding-right: 15px;
	}
	.main_menu ul li{
		margin-left: 10px;
	}
	.main_menu ul li a{
		font-size: 13px;
	}
	.menu_button_box .select_option select {
	    padding: 10px 30px;
	    font-size: 10px;
	}
	.trial_btn a.trial_btn{
		padding: 10px 20px;
	}
	.about_content_box h2 {
	    font-size: 28px;
	    line-height: 35px;
	}
	.service_nav .nav-tabs li {
	    margin-left: 15px;
	    margin-right: 15px;
	}
	.client_title h2,
	.zinco_title h2 {
	    font-size: 36px;
	    line-height: 46px;
	}
	.widget_information_box {
	    padding: 50px 20px;
	}
	.newsletter_form .form_control {
	    width: 300px;
	}
}	
/* Tablet Layout: 768px. */
@media only screen and (min-width: 768px) and (max-width: 991px) {
	/*index_1.html*/
	.zinco_title h2 {
	    font-size: 32px;
	    line-height: 40px;
	}
	.zinco_img_box{
		margin-bottom: 60px;
	}
	.banner_container {
	    max-width: 100%;
	    padding-right: 15px;
	    padding-left: 15px;
	}
	.zinco_logo,
	.zinco_menu,
	.menu_button_box{
		display: none;
	}
	.mean-nav ul li span{
		display: none;
	}
	.header_banner_1 {
	    padding-top: 170px;
	    padding-bottom: 70px;
	}
	.banner_button {
	    margin-bottom: 60px;
	}
	.about_container {
	    max-width: 100%;
	    display: block;
	    margin-left: 15px;
	    margin-right: 15px;
	}
	.about_main_content,
	.about_img_box{
	    width: 100%;
	    float: none;
	    margin-bottom: 60px;
	}
	.about_img_box img{
		width: 100%;
	}
	.about_content_box h2 {
	    font-size: 32px;
	    line-height: 40px;
	}
	.service_nav .nav-tabs {
	    border: none;
	}
	.service_nav .nav-tabs li .service_tab.active .service_box_title {
	    border: none;
	}
	.work_box {
	    margin-bottom: 60px;
	}
	.work_arrow:before,
	.work_arrow:after{
		display: none;
	}
	.widget_information_box {
	    margin-bottom: 60px;
	}
	/*index_1.html*/

	/*index_2.html*/
	.header_banner_2 {
	    padding-bottom: 110px;
	}
	.blog_box_area .blog_box{
		display: block;
	}
	.blog_box_area .blog_box .zinco_img_box{
		float: none;
		width: 100%;
	}
	.blog_box_area .blog_box .zinco_info {
	    margin-left: 00px;
	}
	.zinco_img_box img{
		width: 100%;
	}
	.timeline_line {
	    display: none;
	}
	.zinco_feature_box {
	    margin-bottom: 60px;
	}
	.pricing_box.pricing_box_1 {
	    margin-bottom: 60px;
	}
	.zinco_img_box.ppc_img_box img {
	    width: 100%;
	}
	.project_img.zinco_img {
	    margin-bottom: 40px;
	}
	.zinco_faq_acoridian {
	    margin-bottom: 60px;
	}
	.zinco_counter_box.about_counter_box {
	    margin-bottom: 60px;
	}
	.portfolio_discription_box {
	    padding: 35px 30px;
	}
	.portfolio_btn{
		margin-bottom: 15px;
	}
	.faq_contact_form .form_btn {
	    padding: 30px 90px;
	}
	.testimonial_box_4 .testimonial_text {
	    padding: 40px 20px;
	}
	/*team.html*/
	.client_title h2 {
	    line-height: 45px;
	    font-size: 32px;
	}
	/*shop_details.html*/
	.product_thumb_slide {
	    margin-bottom: 60px;
	}
	.product_thumb_img img {
	    text-align: center;
	    margin: 0 auto;
	}
	.select_item {
	    text-align: center;
	}
	.cart_btn {
		margin: 0 auto;
	    margin-left: 0px;
	}
	.shop_top {
	    text-align: center;
	    margin-bottom: 20px;
	}
	/*blog.html*/
	.post_meta {
	    text-align: center;
	}
	.zinco_sidebar {
	    padding: 50px 10px;
	}
	/*blog-details.html*/
	.single_blog_content .single_content_box {
	    padding: 20px 20px;
	}
	.single_blog_content .zinco_info .post_meta{
		display: none;
	}
	.zinco_blockquote .qoute_text h3 {
	    font-size: 18px;
	    line-height: 27px;
	}
	.tags_area{
		text-align: center;
		margin-bottom: 40px;
	}
	.tags_area.text-right {
	    text-align: center!important;
	}
	.prev_next_area .icon_box {
	    text-align: center;
	    margin: 40px 0;
	}
	.prev_next_text {
	    text-align: center;
	}
	.about_admin_area {
	    display: block;
	    padding: 20px;
	    text-align: center;
	}
	.about_admin_area .admin_images {
	    float: none;
	    margin-bottom: 40px;
	}
	.comment_list_area .single_comment .comment_images {
	    float: none;
	    margin-bottom: 30px;
	}
	.comment_list_area .single_comment {
	    display: block;
	}
	.comment_list_area .single_comment .comment_text {
	    margin-left: 0px;
	}
	.post_button .zinco_btn {
	    padding: 20px 40px;
	}
	/*contact.html*/
	.zinco_content_box h2 {
	    font-size: 32px;
	    line-height: 40px;
	}
	.widget_box {
	    margin-bottom: 60px;
	}
	.widget_newsletter {
	    padding: 20px 10px;	
	}
	.newsletter_content {
	    text-align: center;
	    margin-bottom: 40px;
	}
	.newsletter_form {
	    text-align: center;
	}
	.newsletter_form .form_control{
		width: 100%;
		margin-bottom: 30px;
	}
	/*comeingsoon.html*/
	.offer_timer {
	    text-align: center;
	}
	.counter-column{
		margin-bottom: 40px;
	}
}
/* Mobile Layout: 360px. */
@media only screen and (max-width: 767px) {
	/*index_1.html*/
	.zinco_title h2 {
	    font-size: 32px;
	    line-height: 40px;
	}
	.zinco_img_box{
		margin-bottom: 60px;
	}
	.banner_container {
	    max-width: 100%;
	    padding-right: 15px;
	    padding-left: 15px;
	}
	.zinco_logo,
	.zinco_menu,
	.menu_button_box{
		display: none;
	}
	.mean-nav ul li span{
		display: none;
	}
	.header_banner_1 {
	    padding-top: 170px;
	    padding-bottom: 70px;
	}
	.banner_text h1 {
	    font-size: 35px;
	    line-height: 50px;
	}
	.banner_button {
	    margin-bottom: 60px;
	}
	.banner_text h1 span {
	    font-size: 35px;
	}
	.about_container {
	    max-width: 100%;
	    display: block;
	    margin-left: 15px;
	    margin-right: 15px;
	}
	.about_main_content,
	.about_img_box{
	    width: 100%;
	    float: none;
	    margin-bottom: 60px;
	}
	.about_img_box img{
		width: 100%;
	}
	.about_content_box h2 {
	    font-size: 32px;
	    line-height: 40px;
	}
	.service_nav .nav-tabs {
	    border: none;
	}
	.service_nav .nav-tabs li .service_tab.active .service_box_title {
	    border: none;
	}
	.work_box {
	    margin-bottom: 60px;
	}
	.work_arrow:before,
	.work_arrow:after{
		display: none;
	}
	.widget_information_box {
	    margin-bottom: 60px;
	}
	/*index_1.html*/

	/*index_2.html*/
	.header_banner_2 {
	    padding-bottom: 110px;
	}
	.blog_box_area .blog_box{
		display: block;
	}
	.blog_box_area .blog_box .zinco_img_box{
		float: none;
		width: 100%;
	}
	.blog_box_area .blog_box .zinco_info {
	    margin-left: 00px;
	}
	.zinco_img_box img{
		width: 100%;
	}
	.timeline_line {
	    display: none;
	}
	.zinco_feature_box {
	    margin-bottom: 60px;
	}
	.pricing_box.pricing_box_1 {
	    margin-bottom: 60px;
	}
	.zinco_img_box.ppc_img_box img {
	    width: 100%;
	}
	.project_img.zinco_img {
	    margin-bottom: 40px;
	}
	.zinco_faq_acoridian {
	    margin-bottom: 60px;
	}
	.zinco_counter_box.about_counter_box {
	    margin-bottom: 60px;
	}
	.portfolio_discription_box {
	    padding: 35px 30px;
	}
	.portfolio_btn{
		margin-bottom: 15px;
	}
	.faq_contact_form .form_btn {
	    padding: 30px 90px;
	}
	.testimonial_box_4 .testimonial_text {
	    padding: 40px 20px;
	}
	/*team.html*/
	.client_title h2 {
	    line-height: 45px;
	    font-size: 32px;
	}
	/*shop_details.html*/
	.product_thumb_slide {
	    margin-bottom: 60px;
	}
	.product_thumb_img img {
	    text-align: center;
	    margin: 0 auto;
	}
	.select_item {
	    text-align: center;
	}
	.cart_btn {
		margin: 0 auto;
	    margin-left: 0px;
	}
	.shop_top {
	    text-align: center;
	    margin-bottom: 20px;
	}
	/*blog.html*/
	.post_meta {
	    text-align: center;
	}
	.zinco_sidebar {
	    padding: 50px 10px;
	}
	/*blog-details.html*/
	.single_blog_content .single_content_box {
	    padding: 20px 20px;
	}
	.single_blog_content .zinco_info .post_meta{
		display: none;
	}
	.zinco_blockquote .qoute_text h3 {
	    font-size: 18px;
	    line-height: 27px;
	}
	.tags_area{
		text-align: center;
		margin-bottom: 40px;
	}
	.tags_area.text-right {
	    text-align: center!important;
	}
	.prev_next_area .icon_box {
	    text-align: center;
	    margin: 40px 0;
	}
	.prev_next_text {
	    text-align: center;
	}
	.about_admin_area {
	    display: block;
	    padding: 20px;
	    text-align: center;
	}
	.about_admin_area .admin_images {
	    float: none;
	    margin-bottom: 40px;
	}
	.comment_list_area .single_comment .comment_images {
	    float: none;
	    margin-bottom: 30px;
	}
	.comment_list_area .single_comment {
	    display: block;
	}
	.comment_list_area .single_comment .comment_text {
	    margin-left: 0px;
	}
	.post_button .zinco_btn {
	    padding: 20px 40px;
	}
	/*contact.html*/
	.zinco_content_box h2 {
	    font-size: 32px;
	    line-height: 40px;
	}
	.widget_box {
	    margin-bottom: 60px;
	}
	.widget_newsletter {
	    padding: 20px 10px;	
	}
	.newsletter_content {
	    text-align: center;
	    margin-bottom: 40px;
	}
	.newsletter_form {
	    text-align: center;
	}
	.newsletter_form .form_control{
		width: 100%;
		margin-bottom: 30px;
	}
	/*comeingsoon.html*/
	.offer_timer {
	    text-align: center;
	}
	.counter-column{
		margin-bottom: 40px;
	}
}
/* Wide Mobile Layout: 480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {
	.service_nav .nav-tabs li {
	    margin-left: 10px;
	    margin-right: 10px;
	}

}